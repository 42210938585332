<template>
    <div>
        <div class="row">
            <div class="col text-center">
                <edit-member :member="member" class="ml-2" light v-slot="{ visible, toggle }">
                    <n-button round
                              type="success"
                              @click.native="toggle()">
                        <i class="fas fa-edit mr-2 font-weight-bold" />
                        Update Member Information
                    </n-button>
                </edit-member>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-sm-12">
                <card class="card-nav-tabs member-card" header-classes="card-header-warning">
                    <div slot="header">
                        <div class="initials-icon" v-if="member.init">
                            {{ member.init | uppercase }}
                        </div>

                        {{ member.full_name }} <span><small>({{ member.id }})</small></span>
                    </div>

                    <div>
                        <b>Facility:</b> {{ member.facility_id | uppercase }}
                        <span class="ml-4 text-danger" v-if="member.bue_facility && member.bue_facility.toLowerCase() !== member.facility_id.toLowerCase()">
                            <i>MyNATCA Facility:</i> <b>{{ member.bue_facility | uppercase }}</b>
                        </span><br>
                        <b>Position:</b> {{ position }}
                    </div>

                    <ul class="list-unstyled mt-3">
                        <li>
                            <b><i class="fas fa-user-clock"></i> Controller Type:</b>
                            {{ employeeType || 'Not Specified' }}
                        </li>
                        <li v-if="member.email">
                            <b><i class="fas fa-at"></i> Email: </b>
                            {{ member.email }}
                        </li>
                        <li v-if="member.cell_phone">
                            <b><i class="fas fa-mobile-alt"></i> Phone: </b>
                            {{ member.cell_phone }}
                        </li>
                    </ul>
                    <div v-if="is('arearep', member.area_id) && member.notes">
                        <hr>
                        <h6>Notes</h6>
                        <small><i>** Not visible to controller **</i></small>
                        <p>{{ member.notes }}</p>
                    </div>
                </card>
                <card class="card-nav-tabs member-card">
                    <div slot="header">
                        Bid Status
                    </div>
                    <up-to-bid-table :bidders="filteredBidders"></up-to-bid-table>
                </card>
            </div>
            <div class="col-md-6 col-sm-12">
                <card class="card-nav-tabs member-card">
                    <div slot="header">
                        Login Information
                    </div>
                    <ul class="list-unstyled" v-if="member.user_id">
                        <li><b>Username:</b> {{ member.email }}</li>
                        <li><b>Role:</b> {{ member.role_id ? roleNames[member.role_id] : 'Not Registered' }}</li>
                    </ul>
                    <span v-else>Controller has not created user account</span>
                </card>
                <card class="card-nav-tabs member-card">
                    <div slot="header">
                        Dates
                    </div>
                    <div class="row">
                        <div class="col-4 font-weight-bold">NATCA BU</div>
                        <div class="col">{{ member.NATCA_date | utcDisplay }}</div>
                    </div>
                    <div class="row">
                        <div class="col-4 font-weight-bold">BU</div>
                        <div class="col">{{ member.BU_date | utcDisplay }}</div>
                    </div>
                    <div class="row">
                        <div class="col-4 font-weight-bold">EOD</div>
                        <div class="col">{{ member.EOD_date | utcDisplay }}</div>
                    </div>
                    <div class="row">
                        <div class="col-4 font-weight-bold">SCD</div>
                        <div class="col">{{ member.SCD_date | utcDisplay }}</div>
                    </div>
                </card>
            </div>
        </div>
    </div>
</template>
<script>
import { EditMember } from '@/components'
import UpToBidTable from '@/views/area/UpToBidTable'

import { mapGetters } from 'vuex'
import { RepositoryFactory } from '@/repositories/RepositoryFactory'
const MemberRepository = RepositoryFactory.get('member')

export default {
  name: 'member',
  components: {
    EditMember,
    UpToBidTable
  },
  data () {
    return {
      member: {}
    }
  },
  computed: {
    ...mapGetters({
      facility: 'facility/facility',
      area: 'area/area',
      is: 'auth/is',
      roleNames: 'auth/roleNames',
      employeeTypes: 'facility/employeeTypes',
      bidders: 'bidder/bidders'
    }),
    /** Get friendly name for controller local position */
    position () {
      const p = this.member.Position
      if (p === 'p') return 'FACREP'
      if (p === 'vp') return 'VP'
      return 'Controller'
    },
    /** Get employee type, if available */
    employeeType () {
      const type = this.employeeTypes[this.member.employee_type_id]
      if (type) {
        return type.type_name
      }
      return null
    },
    filteredBidders () {
      return _.filter(this.bidders, bidder => bidder.member_id === this.$route.params.member_id)
    }
  },
  methods: {
    bidDayRange (range) {
      return this.$options.filters.dayRange(range[0].calendar_id, range[range.length - 1].calendar_id)
    },
    fetchMember () {
      MemberRepository.getMember(this.$route.params.member_id)
        .then(r => {
          this.member = r.data
        })
    },
    bidDays (days) {
      return _.map(days, d => this.$options.filters.utcDisplay(d.calendar_id)).join(', ')
    }
  },
  created () {
    this.fetchMember()
  }
}
</script>
<style>

</style>
